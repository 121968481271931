import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPropsAction } from './props.types'

const initialState: IPropsAction = {
  screenSaver: { open: false, items: [] },
}

export const propsSlice = createSlice({
  name: 'props',
  initialState,
  reducers: {
    setProps(state: IPropsAction, action: PayloadAction<IPropsAction>) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: () => {},
})

export const propsActions = propsSlice.actions
export const propsReducer = propsSlice.reducer
