import { useDispatch } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { authActions } from '../../store/auth/auth.slice'
import { constActions } from '../../store/constants/constants.slice'
import { modalActions } from '../../store/modals/modal.slice'
import { propsActions } from '../../store/props/props.slice'

const actions = {
  ...authActions,
  ...constActions,
  ...modalActions,
  ...propsActions,
}

export const useReduxActions = () => {
  const dispatch = useDispatch()
  return bindActionCreators(actions, dispatch)
}
