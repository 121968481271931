import React, { useState } from 'react'
import { IconButton, Drawer, Box, Container, Grid, Theme, Button, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import { CloseIcon, BurgerIcon } from '../../imgs'
import { size } from '../../configs'
import { detectNetwork } from '../../utils'
import { useReduxActions, useReduxSelector } from '../../hooks'
import { getScreenSaver } from '../../api'

export const Menu: React.FC = (): JSX.Element => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { setProps, setAlert } = useReduxActions()

  const xl = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xl'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const props = useReduxSelector((state) => state.props)

  const [open, setOpen] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const onOpen = () => setOpen(true)

  const onClose = () => setOpen(false)

  const onMainReview = () => {
    if (pathname === '/') detectNetwork(() => navigate('../review'))
    else navigate({ pathname: '../', search: '?index=main' })
  }

  const onMainInterview = () => {
    if (pathname === '/') detectNetwork(() => navigate('../interview'))
    else navigate({ pathname: '../', search: '?index=main' })
  }

  const onDiagnostics = () => detectNetwork(() => navigate('../diagnostics'))

  const onOffers = () => detectNetwork(() => navigate('../offers'))

  const onGallery = () => detectNetwork(() => navigate('../gallery'))

  const onScreenSaver = () => {
    detectNetwork(() => {
      setDisabled(true)

      getScreenSaver()
        .then((res) => {
          if (res.data.length > 0) {
            setProps({ ...props, screenSaver: { open: true, items: res.data || [] } })
            onClose()
          } else {
            setAlert({ alert: { message: { info: ['Медиа файлы для заставки не загружены!'] } } })
          }
        })
        .finally(() => {
          setDisabled(false)
        })
    })
  }

  return (
    <Box width={{ xs: 268 / size.scale, md: 268, xl: 268 * size.scale }} display="flex" justifyContent="flex-end">
      <IconButton color="secondary" onClick={onOpen}>
        <BurgerIcon
          width={!xl ? 80 * size.scale : md ? 80 / size.scale : 80}
          height={!xl ? 80 * size.scale : md ? 80 / size.scale : 80}
        />
      </IconButton>

      <Drawer anchor="right" open={open} onClose={onClose}>
        <Container className={classes.drawerContainer}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <IconButton color="secondary" onClick={onClose}>
                <CloseIcon width={md ? 80 / size.scale : 80} height={md ? 80 / size.scale : 80} />
              </IconButton>
            </Grid>
          </Grid>
          <Container className={classes.contentContainer}>
            <Grid container direction="column" justifyContent="space-between" height="100%">
              <Grid item>
                {/* <Box className={classes.title}>Диагностика</Box> */}
                {/* <Box className={classes.text}> */}
                {/*  Выберите диагностику, ответьте */}
                {/*  <br /> */}
                {/*  на несколько вопросов и узнайте */}
                {/*  <br />о том, какая услуга подойдет вашим */}
                {/*  <br /> */}
                {/*  волосам и коже головы. */}
                {/* </Box> */}
                <Box mt={`${20 / size.scale}px`} mb={`${20 / size.scale}px`}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={onDiagnostics}
                    className={classes.btnSecondary}
                  >
                    Пройти диагностику
                  </Button>
                </Box>
                <Box mb={`${20 / size.scale}px`}>
                  <Button variant="contained" color="primary" className={classes.btnPrimary} onClick={onOffers}>
                    Спец. предложения
                  </Button>
                </Box>
                <Box mb={`${20 / size.scale}px`}>
                  <Button variant="contained" color="primary" className={classes.btnPrimary} onClick={onGallery}>
                    Галерея
                  </Button>
                </Box>
                <Box mb={`${20 / size.scale}px`}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={disabled}
                    onClick={onScreenSaver}
                    className={classes.btnPrimary}
                  >
                    Заставка
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box mb={`${20 / size.scale}px`}>
                  <Button variant="contained" color="primary" className={classes.btnPrimary} onClick={onMainInterview}>
                    Оценить уровень работы
                  </Button>
                </Box>
                <Box mb={`${68 / size.scale}px`}>
                  <Button variant="contained" color="primary" className={classes.btnPrimary} onClick={onMainReview}>
                    {pathname === '/' ? 'Оставить отзыв' : 'На главную'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Drawer>
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, font, palette }: Theme) => ({
  drawerContainer: {
    height: '100vh',
    backgroundColor: palette.primary.main,
    padding: `${24 * size.scale}px!important`,
    [down(2000)]: {
      padding: `${24}px!important`,
    },
    [down(1000)]: {
      padding: `${24 / size.scale}px!important`,
    },
  },
  contentContainer: {
    height: `calc(100% - ${96}px)`,
    backgroundColor: palette.primary.main,
    padding: `0 ${40 * size.scale}px!important`,
    [down(2000)]: {
      padding: `0 ${40}px!important`,
    },
    [down(1000)]: {
      height: `calc(100% - ${96 / size.scale}px)`,
      padding: `0 ${40 / size.scale}px!important`,
    },
  },
  title: {
    lineHeight: 1,
    fontWeight: 800,
    fontSize: 40 * size.scale,
    textTransform: 'uppercase',
    marginBottom: 30 * size.scale,
    [down(2000)]: {
      fontSize: 40,
      marginBottom: 30,
    },
    [down(1000)]: {
      fontSize: 40 / size.scale,
      marginBottom: 30 / size.scale,
    },
  },
  text: {
    fontWeight: 400,
    lineHeight: 1.4,
    fontFamily: font.secondary,
    fontSize: 26 * size.scale,
    marginBottom: 60 * size.scale,
    [down(2000)]: {
      fontSize: 26,
      marginBottom: 60,
    },
    [down(1000)]: {
      fontSize: 26 / size.scale,
      marginBottom: 60 / size.scale,
    },
  },
  btnPrimary: {
    minWidth: `${484 * size.scale}px!important`,
    color: `${palette.primary.dark}!important`,
    borderColor: `${palette.primary.dark}!important`,
    [down(2000)]: {
      minWidth: `${484}px!important`,
    },
    [down(1000)]: {
      minWidth: `${484 / size.scale}px!important`,
    },
  },
  btnSecondary: {
    minWidth: `${484 * size.scale}px!important`,
    borderColor: `${palette.primary.dark}!important`,
    [down(2000)]: {
      minWidth: `${484}px!important`,
    },
    [down(1000)]: {
      minWidth: `${484 / size.scale}px!important`,
    },
  },
}))
