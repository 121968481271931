import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { constReducer } from './constants/constants.slice'
import { authReducer } from './auth/auth.slice'
import { modalReducer } from './modals/modal.slice'
import { propsReducer } from './props/props.slice'

export const store = configureStore({
  reducer: {
    constants: constReducer,
    auth: authReducer,
    modals: modalReducer,
    props: propsReducer,
  },
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
