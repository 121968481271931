/* eslint-disable import/no-unresolved,max-len */
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'

import { FC, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Grid, Slide, Theme, useMediaQuery } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper/modules'
import { getScreenSaver } from '../../api'
import { Gallery, Media } from '../../types'
import { detectNetwork } from '../../utils'
import { useReduxActions, useReduxSelector } from '../../hooks'

type TSlide = {
  src: string
  index: number
  length?: number
  media?: Gallery[]
  onPlayVideo?: () => void
  type: 'image' | 'video' | null
  onEndedVideo?: (index: number) => void
}

const { REACT_APP_DEBUG: DEBUG } = process.env

const SlideContent: FC<TSlide> = ({ index, length, type, src, media, onPlayVideo, onEndedVideo }) => {
  const url = `https://losalons-2023.xtest.atoms.ru/endpoint${src}`

  const classes = useStyles()

  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const onPlay = () => {
    if (onPlayVideo) onPlayVideo()
  }

  const onEnded = () => {
    if (onEndedVideo) onEndedVideo(index || 0)
  }

  return (
    <Box width="100vw" height="100dvh">
      {type === 'video' && (
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <Grid item>
            <video
              muted
              src={url}
              playsInline
              onPlay={onPlay}
              onEnded={onEnded}
              loop={length === 1}
              id={`slide-${index}`}
              autoPlay={index === 0}
              width={md ? 'auto' : '100%'}
              crossOrigin={DEBUG ? undefined : 'anonymous'}
            />
          </Grid>
        </Grid>
      )}
      {type === 'image' && (
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <Grid item>
            <img src={url} alt="screensaver" className={classes.img} />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export const ScreenSave: FC = (): JSX.Element => {
  const timeout = 5 * 60

  const classes = useStyles()
  const { setProps } = useReduxActions()

  const props = useReduxSelector((state) => state.props)

  const {
    screenSaver: { open = false, items = [] },
  } = props

  const [swiper, setSwiper] = useState<any>()
  const [timer, setTimer] = useState<number>(0)
  const [content, setContent] = useState<boolean>(false)

  const onTouch = () => {
    setProps({ ...props, screenSaver: { ...props.screenSaver, open: false } })
    setTimeout(() => setContent(false), 400)
    setTimer((prev) => prev + 1)
  }

  const onOpen = () => {
    detectNetwork(() => {
      getScreenSaver().then((res) => {
        setTimer(0)
        setProps({ ...props, screenSaver: { open: true, items: res.data || [] } })
      })
    })
  }

  const onNextSlide = ({ activeIndex }: any) => {
    const video: HTMLVideoElement | null = document.getElementById(`slide-${activeIndex}`) as HTMLVideoElement
    video?.play()
  }

  const onPlayVideo = () => {
    swiper?.autoplay?.stop()
  }

  const onEndedVideo = (index: number) => {
    const video: HTMLVideoElement | null = document.getElementById(`slide-${index}`) as HTMLVideoElement
    video?.pause()
    video.currentTime = 0

    swiper?.autoplay?.start()
    swiper?.slideNext()
  }

  const uploadScreenSaver = () => {
    detectNetwork(() => {
      getScreenSaver().then((res) => {
        setProps({ ...props, screenSaver: { ...props.screenSaver, items: res.data || [] } })
        setTimer((prev) => prev + 1)
      })
    })
  }

  useEffect(() => {
    uploadScreenSaver()
  }, [])

  useEffect(() => {
    if (open) setContent(true)
  }, [open])

  useEffect(() => {
    if (!open && timer < timeout && items.length > 0) {
      setTimeout(setTimer, 1000, timer + 1)
    } else if (timer === timeout) onOpen()
  }, [timer])

  return (
    <Slide in={open} timeout={{ enter: 300, exit: 300 }}>
      <Box className={classes.screen} onClick={onTouch}>
        {content && (
          <Swiper
            loop
            noSwiping
            speed={1000}
            effect="fade"
            slidesPerView={1}
            onSwiper={setSwiper}
            allowTouchMove={false}
            fadeEffect={{ crossFade: true }}
            modules={[Autoplay, EffectFade]}
            onActiveIndexChange={onNextSlide}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
          >
            {items[0].media.map((k: Media, i: number) => (
              <SwiperSlide key={`slide-${i}`}>
                <SlideContent
                  index={i}
                  src={k.src}
                  type={k.type}
                  onPlayVideo={onPlayVideo}
                  onEndedVideo={onEndedVideo}
                  length={items.media?.length || 0}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>
    </Slide>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, font, palette }: Theme) => ({
  screen: {
    top: 0,
    left: 0,
    zIndex: 10000,
    width: '100dvw',
    height: '100dvh',
    position: 'fixed',
    backgroundColor: '#000000',
  },
  img: {
    width: '100%',
  },
}))
