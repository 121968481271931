import React, { ChangeEvent, ReactElement } from 'react'
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Theme } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import { makeStyles } from '@mui/styles'

type Props = {
  form: UseFormReturn<any>
  name: string
  label: string | ReactElement
  color?: 'primary' | 'secondary'
  icon: ReactElement
  checked: ReactElement
  disabled?: boolean
  required?: boolean
  onChange?(e: ChangeEvent<HTMLInputElement>, checked: boolean): void
}

export const CheckboxInput: React.FC<Props> = ({
  form,
  name,
  label,
  icon,
  checked,
  color = 'primary',
  required = false,
  disabled = false,
  onChange: change,
}): JSX.Element => {
  const classes = useStyles()
  const { control, formState } = form

  return (
    <Box mb={3 / 2} className={classes.helper}>
      <FormControl>
        <FormControlLabel
          control={
            <Controller
              control={control}
              name={name}
              rules={{ required }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  color={color}
                  onBlur={onBlur}
                  onChange={(e, c) => {
                    onChange(e, c)
                    if (change) change(e, c)
                  }}
                  checked={value}
                  inputRef={ref}
                  disabled={disabled}
                  checkedIcon={checked}
                  icon={icon}
                />
              )}
            />
          }
          label={label}
        />
        <FormHelperText>
          <>{formState.errors[name]?.message}</>
        </FormHelperText>
      </FormControl>
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  helper: {
    '& .MuiFormHelperText-root': {
      marginTop: '-3px!important',
    },
  },
}))
