import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useReduxSelector, useReduxActions } from '../../hooks'

export const LogoutPage = () => {
  const navigate = useNavigate()
  const { logout } = useReduxActions()
  const { auth } = useReduxSelector((state) => state.auth)

  useEffect(() => {
    if (auth) logout()
    else navigate('../', { replace: true })
  }, [auth])

  return <></>
}
