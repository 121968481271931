import { toast } from 'react-toastify'
import { TOAST } from '../types'
import { ErrorNetwork } from './content.toast'

export const errorNetworkToast = () => {
  toast.error(ErrorNetwork, {
    toastId: TOAST.ERROR_NETWORK,
    containerId: TOAST.GLOBAL_CONTAINER_ID,
    position: toast.POSITION.TOP_CENTER,
    theme: 'colored',
    autoClose: 4000,
  })
}
