import * as ls from 'local-storage'
import axios from 'axios'
import { DiagnosticsStoreProps, ReviewStore, TOKEN, WaitingListMakeProps } from '../types'
import { fetchPrivateAPIToken, withPrivateAPIToken, withPublicAPIToken } from './service'

export const constants = async () =>
  withPublicAPIToken({
    url: '/api/v1/constant',
    method: 'get',
  })

export const salons = async () =>
  withPublicAPIToken({
    url: '/api/v1/users',
    method: 'get',
  })

export const signin = async (username: number, password: string) => fetchPrivateAPIToken(username, password)

export const logout = async () => {
  return withPrivateAPIToken({
    url: '/private/user/logout',
    method: 'post',
  }).then((_r) => {
    ls.remove(TOKEN.PRIVATE)
  })
}

export const auth = async () =>
  withPrivateAPIToken({
    url: '/api/v1/user',
    method: 'get',
  })

export const diagnosticsList = async () =>
  withPrivateAPIToken({
    url: '/api/v1/interview',
    method: 'get',
  })

export const diagnosticsStore = async (data: DiagnosticsStoreProps) =>
  withPrivateAPIToken({
    url: '/api/v1/interview/store',
    method: 'post',
    data: { ...data },
  })

export const waitingListMake = async (data: WaitingListMakeProps) =>
  withPrivateAPIToken({
    url: '/api/v1/waiting_list/make',
    method: 'post',
    data: { ...data },
  })

export const reviewAnswers = async () =>
  withPublicAPIToken({
    url: '/api/v1/review/answer',
    method: 'get',
  })

export const reviewQuestions = async () =>
  withPublicAPIToken({
    url: '/api/v1/review/question',
    method: 'get',
  })

export const reviewStores = async (data: ReviewStore) =>
  withPrivateAPIToken({
    url: '/api/v1/review/store',
    method: 'post',
    data: { ...data },
  })

export const ratingSalonStore = async (data: { value: number }) =>
  withPrivateAPIToken({
    url: '/api/v1/rating/store',
    method: 'post',
    data: { ...data },
  })

export const getOffers = async () =>
  await withPrivateAPIToken({
    url: '/api/v1/special_offer',
    method: 'get',
  })

export const getGallery = async () =>
  await withPrivateAPIToken({
    url: '/api/v1/background?background_type=gallery',
    method: 'get',
  })

export const getScreenSaver = async () =>
  await withPrivateAPIToken({
    url: '/api/v1/background?background_type=screensaver',
    method: 'get',
  })

export const apiGetNoCache = (url: string) =>
  axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
  })
